import React from "react";

const NotFound = () => {
  return (
    <div className="flex min-h-full text-3xl font-bold justify-center items-center">
      Not Found (404)
    </div>
  );
};

export default NotFound;
