import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetActiveCodes, GetUsers } from "../redux/apiCalls/subscribersApiCall";

const SuperAdminHome = () => {
  // جلب عدد الطلاب من Redux
  const countUsers = useSelector((state) => state.users.countUsers);
  const selectCodes = useSelector((state) => state.users.codes);

  const dispatch = useDispatch();

  console.log(selectCodes.length);

  useEffect(() => {
    dispatch(GetUsers(1, 1000000, null)); // جلب عدد كبير جداً من المستخدمين دفعة واحدة
    dispatch(GetActiveCodes());
  }, [dispatch]);

  return (
    <div className="p-6 grid grid-cols-3 gap-[40px_60px] mt-[43px]">
      <div className="p-[18px] w-[280px] rounded-[15px] shadow-shadow bg-[white] flex flex-col justify-between">
        <div className="bg-blue-100 flex items-center gap-4 p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold">عدد الطلاب:</h2>
          <p className="text-xl font-bold text-blue-600">{countUsers}</p>
        </div>
      </div>
      <div className="p-[18px] w-[280px] rounded-[15px] shadow-shadow bg-[white] flex flex-col justify-between">
        <div className="bg-blue-100 flex items-center gap-4 p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold">عدد الأكواد المفعلة:</h2>
          <p className="text-xl font-bold text-blue-600">
            {selectCodes.length}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminHome;
