import { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeadInputs } from "../HeadInputs/HeadInputs";
import {
  GetSides,
  GetSubjects,
  GetUnits,
  GetLessons,
  AddQuestions,
  ExcelFile,
  getQuestionCount,
} from "../../redux/apiCalls/subscribersApiCall";
import { ThemeProvider, createTheme } from "@mui/material";
import { getIdCookie } from "../../utils/cockies";
import { QuestionInput } from "../QuestionInput/QuestionInput";
import Loading from "../Loading";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import CircularWithValueLabel from "../Progress";
import { FormAddAtachment } from "../FormAddAtachment/FormAddAtachment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#042128",
    },
    text: {
      primary: "#042128",
    },
  },
  typography: {
    fontFamily: "Tajawal, sans-serif", // Change the font family here
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: "100%",
        },
        inputRoot: {
          width: "100%",
          padding: "0",
          paddingRight: "0px !important",
        },
        input: {
          border: "1px solid #004556",
          padding: "10px 35px 10px 14px !important",
          borderRadius: "15px",
          "&:focus": {
            outline: "none !important",
          },
        },
        popupIndicator: {
          display: "none",
        },
      },
    },
  },
});
export const FormAddQuestion = () => {
  const [progress, setProgress] = useState(0);
  const [atachId, setAtachId] = useState("");
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const addQuestion = useRef("");
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [selectedSide, setSelectedSide] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedLesson, setSelectedLesson] = useState();
  const handleAutocompleteChange = (event, value) => {
    if (value) {
      setSelectedSide(value.value);
    } else {
      setSelectedSide(""); // Handle case when value is cleared
    }
  };
  const handleAutocompleteChange1 = (event, value) => {
    if (value) {
      setSelectedSubject(value.value);
    } else {
      setSelectedSubject(""); // Handle case when value is cleared
    }
  };

  const handleAutocompleteChange2 = (event, value) => {
    if (value) {
      setSelectedUnit(value.value);
    } else {
      setSelectedUnit(""); // Handle case when value is cleared
    }
  };
  const handleAutocompleteChange3 = (event, value) => {
    if (value) {
      setSelectedLesson(value.value);
    } else {
      setSelectedLesson(""); // Handle case when value is cleared
    }
  };
  const handleAutocompleteChange4 = (event, value) => {
    if (value) {
      setSelectedAnswer(value.value);
    } else {
      setSelectedAnswer(""); // Handle case when value is cleared
    }
  };

  if (selectedSubject) {
    console.log(selectedSubject);
  }

  const sides = useSelector((state) => state.users.sides);
  const subjects = useSelector((state) => state.users.subjects);
  const lessons = useSelector((state) => state.users.lessons);
  const token = getIdCookie().token;
  const units = useSelector((state) => state.users.units);
  const loading = useSelector((state) => state.users.delete);
  const questionCount = useSelector((state) => state.users.questionCount);
  useEffect(() => {
    dispatch(GetSides());
  }, [dispatch]);
  useEffect(() => {
    if (selectedSide) {
      dispatch(GetSubjects(selectedSide, 1, 1000));
    }
  }, [dispatch, selectedSide]);
  useEffect(() => {
    if (selectedSubject) {
      dispatch(GetUnits(token, 1, 1000, selectedSubject));
      dispatch(getQuestionCount(token, selectedSubject));
    }
  }, [dispatch, token, selectedSubject]);
  useEffect(() => {
    if (selectedUnit) {
      dispatch(GetLessons(token, 1, 1000, selectedUnit));
    }
  }, [dispatch, selectedUnit, token]);
  const [image, setImage] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const answer = [
    { label: "الجواب a", value: "a" },
    { label: "الجواب b", value: "b" },
    { label: "الجواب c", value: "c" },
    { label: "الجواب d", value: "d" },
    { label: "الجواب e", value: "e" },
  ];
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const handelAddQuestion = async (e) => {
    e.preventDefault();

    if (image && !excelFile) {
      const cAnswer = addQuestion.current.cAnswer.value;
      const dAnswer = addQuestion.current.dAnswer.value;
      const eAnswer = addQuestion.current.eAnswer.value;
      const formData = new FormData();
      const explanation = addQuestion.current.explanation.value;
      const issue = addQuestion.current.issue.value;

      if (explanation) formData.append("explanation", explanation);
      if (issue) formData.append("issue", issue);

      formData.append("question", addQuestion.current.question.value);
      formData.append("aAnswer", addQuestion.current.aAnswer.value);
      formData.append("bAnswer", addQuestion.current.bAnswer.value);
      formData.append("difficulty", addQuestion.current.level.value);
      formData.append("correctAnswer", selectedAnswer);
      formData.append("lesson", selectedLesson);
      formData.append("subject", selectedSubject);
      formData.append("unit", selectedUnit);
      formData.append("image", image);

      // Conditionally append optional answers

      if (cAnswer) formData.append("cAnswer", cAnswer);
      if (dAnswer) formData.append("dAnswer", dAnswer);
      if (eAnswer) formData.append("eAnswer", eAnswer);

      dispatch(AddQuestions(formData)).then((data) => {
        setAtachId(data.question._id);
        setLoad(true);
      });
      return;
    }

    if (excelFile) {
      const formData1 = new FormData();
      formData1.append("file", excelFile);
      dispatch(
        ExcelFile(
          selectedLesson,
          selectedUnit,
          selectedSubject,
          formData1,
          (percentCompleted) => {
            setProgress(percentCompleted);
          }
        )
      ).then(() => {
        navigate("/qestions");
      });
      return;
    }

    if (!image && !excelFile) {
      const cAnswer = addQuestion.current.cAnswer.value;
      const dAnswer = addQuestion.current.dAnswer.value;
      const eAnswer = addQuestion.current.eAnswer.value;
      const formData = new FormData();
      const explanation = addQuestion.current.explanation.value;
      const issue = addQuestion.current.issue.value;

      formData.append("question", addQuestion.current.question.value);
      formData.append("aAnswer", addQuestion.current.aAnswer.value);
      formData.append("bAnswer", addQuestion.current.bAnswer.value);
      formData.append("difficulty", addQuestion.current.level.value);
      formData.append("correctAnswer", selectedAnswer);
      formData.append("lesson", selectedLesson);
      formData.append("subject", selectedSubject);
      formData.append("unit", selectedUnit);

      if (issue) formData.append("issue", issue);
      if (explanation) formData.append("explanation", explanation);

      // Conditionally append optional answers
      if (cAnswer) formData.append("cAnswer", cAnswer);
      if (dAnswer) formData.append("dAnswer", dAnswer);
      if (eAnswer) formData.append("eAnswer", eAnswer);

      dispatch(AddQuestions(formData)).then((data) => {
        setAtachId(data.question._id);
        setLoad(true);
      });
    }
  };
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setExcelFile(selectedFile);
  };
  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <HeadInputs title="إضافة سؤال" />
        <div>
          <label
            className="flex items-center text-main text-[18px] font-[700] underline gap-[5px] cursor-pointer"
            htmlFor="excel"
          >
            <img src="/assests/questionImage/excel.svg" alt="" />
            {excelFile ? "تم اختيار ملف" : "رفع ملف أسئلة"}
          </label>
          <input
            className="hidden"
            id="excel"
            type="file"
            onChange={handleFileChange}
          />
        </div>
      </div>
      <div className="ques-add w-full flex items-start gap-[120px] bg-[white]  py-[70px] px-[80px] rounded-[15px] my-[43px] ">
        <div className="inputs flex-1">
          {progress > 0 && <CircularWithValueLabel progress={progress} />}
          <form
            className="flex flex-col gap-[35px]"
            onSubmit={handelAddQuestion}
            ref={addQuestion}
          >
            <div className="box-input">
              <label
                className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                htmlFor=" اسم الفرع"
              >
                اسم الفرع
              </label>
              <ThemeProvider theme={theme}>
                <Autocomplete
                  disablePortal
                  id=" اسم الفرع"
                  name="country"
                  options={sides.map((side) => ({
                    label: side.name,
                    value: side._id,
                  }))}
                  value={selectedSide}
                  onChange={handleAutocompleteChange}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        className: "hidden",
                      }}
                    />
                  )}
                />
              </ThemeProvider>
            </div>
            <div className="box-input">
              <label
                className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                for="اسم المادة"
              >
                اسم المادة
              </label>
              <ThemeProvider theme={theme}>
                <Autocomplete
                  disablePortal
                  id="اسم المادة"
                  name="subject"
                  options={subjects.map((subject) => ({
                    label: subject.name,
                    value: subject._id,
                  }))}
                  value={selectedSubject}
                  onChange={handleAutocompleteChange1}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        className: "hidden",
                      }}
                    />
                  )}
                />
              </ThemeProvider>
            </div>
            <div className="box-input">
              <label
                className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                for="اسم الوحدة"
              >
                اسم الوحدة
              </label>
              <ThemeProvider theme={theme}>
                <Autocomplete
                  disablePortal
                  id="اسم الوحدة"
                  name="unit"
                  options={units.map((unit) => ({
                    label: unit.unitName,
                    value: unit._id,
                  }))}
                  value={selectedUnit}
                  onChange={handleAutocompleteChange2}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        className: "hidden",
                      }}
                    />
                  )}
                />
              </ThemeProvider>
            </div>
            <div className="box-input">
              <label
                className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                for="اسم الدرس"
              >
                اسم الدرس
              </label>
              <ThemeProvider theme={theme}>
                <Autocomplete
                  disablePortal
                  id="اسم الدرس"
                  name="lesson"
                  options={lessons.map((lesson) => ({
                    label: lesson.lessonName,
                    value: lesson._id,
                  }))}
                  value={selectedLesson}
                  onChange={handleAutocompleteChange3}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        className: "hidden",
                      }}
                    />
                  )}
                />
              </ThemeProvider>
            </div>
            {excelFile ? null : (
              <>
                <div className="box-input">
                  <label
                    className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                    for="تحميل صورة"
                  >
                    تحميل صورة
                  </label>
                  <label
                    className="flex items-center gap-[8px] px-[14px] py-[10px] border-solid border-[1px] border-[#004556] text-input rounded-[15px]"
                    for="تحميل صورة"
                  >
                    <img
                      className="w-[24px] h-[24px]"
                      src="/assests/questionImage/camera.svg"
                      alt=""
                    />
                    {image ? (
                      <span className="text-[14px] font-[500]">
                        تم اختيار صورة
                      </span>
                    ) : null}
                  </label>
                  <input
                    type="file"
                    className="hidden w-full px-[14px] py-[10px] border-solid border-[1px] border-[#004556] text-input rounded-[15px] cursor-text overflow-y-auto focus:outline-none"
                    id="تحميل صورة"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="box-input">
                  <label
                    className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                    htmlFor="issue"
                  >
                    المسألة رقم {questionCount}
                  </label>
                  <textarea
                    className="w-full h-[93px] resize-none px-[14px] py-[10px] border-solid border-[1px] border-[#004556] text-input rounded-[15px] cursor-text overflow-y-auto focus:outline-none"
                    name="issue"
                    id="issue"
                  />
                </div>
                <div className="box-input">
                  <label
                    className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                    htmlFor="question"
                  >
                    نص السؤال
                  </label>
                  <textarea
                    className="w-full h-[93px] resize-none px-[14px] py-[10px] border-solid border-[1px] border-[#004556] text-input rounded-[15px] cursor-text overflow-y-auto focus:outline-none"
                    name="question"
                    id="question"
                  />
                </div>
                <div className="box-input">
                  <label
                    className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                    htmlFor="explanation"
                  >
                    شرح السؤال
                  </label>
                  <textarea
                    className="w-full h-[93px] resize-none px-[14px] py-[10px] border-solid border-[1px] border-[#004556] text-input rounded-[15px] cursor-text overflow-y-auto focus:outline-none"
                    name="explanation"
                    id="explanation"
                  />
                </div>
                <QuestionInput />
                <div className="box-input">
                  <label
                    className="flex items-end mb-[12px] text-[21px] font-[500] text-black  cursor-text"
                    htmlFor="مستوى السؤال"
                  >
                    مستوى السؤال
                  </label>
                  <select
                    className="w-full px-[14px] py-[10px] border-solid border-[1px] border-black rounded-[15px] cursor-text overflow-y-auto focus:outline-none"
                    id="مستوى السؤال"
                    name="level"
                  >
                    <option value="" hidden></option>
                    <option value="صعب">صعب</option>
                    <option value="متوسط">متوسط</option>
                    <option value="سهل">سهل</option>
                  </select>
                </div>
                <div className="box-input">
                  <label
                    className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                    htmlFor="الجواب الصحيح"
                  >
                    الجواب الصحيح
                  </label>
                  <ThemeProvider theme={theme}>
                    <Autocomplete
                      disablePortal
                      id="الجواب الصحيح"
                      name="country"
                      options={answer}
                      value={selectedAnswer}
                      onChange={handleAutocompleteChange4}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            className: "hidden",
                          }}
                        />
                      )}
                    />
                  </ThemeProvider>
                </div>
              </>
            )}
            <button className=" text-[white] group w-full py-[14px]  relative z-10 shadow-shadow rounded-[15px]">
              <span className="bg-main group-hover:scale-[1.01] transition duration-[0.2s] absolute left-0 top-0 w-full h-full rounded-[15px] z-[-2]"></span>
              {loading ? <Loading /> : "حفظ"}
            </button>
          </form>
        </div>
        <div className="w-[280px] h-[252px] sticky top-[10px]">
          <img
            className="w-full h-full"
            src="/assests/imageInput/person.svg"
            alt=""
          />
        </div>
      </div>
      {load && <FormAddAtachment id={atachId} />}
    </Fragment>
  );
};
