import { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import QuestionCard from "../components/QuestionCard/QuestionCard";
import PaginationCom from "../components/Pagination";
import DrowbSubjects from "../components/DrowbSubjects/DrowbSubjects";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/Loading";
import {
  GetSides,
  GetSubjects,
  GetQuestions,
  DeleteQuestions,
} from "../redux/apiCalls/subscribersApiCall";
import { PER_PAGE } from "../utils/arrays";
import { dataActions } from "../redux/slices/subscribersSlice";

export default function Question() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const subjects = useSelector((state) => state.users.subjects);
  const loading = useSelector((state) => state.users.loading);
  const questions = useSelector((state) => state.users.questions);
  const sides = useSelector((state) => state.users.sides);
  const [sideId, setSideId] = useState(sides[0]?._id);
  const updateValue = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(GetSides());
  }, [dispatch]);

  useEffect(() => {
    if (sides.length > 0) {
      setSideId(sides[0]._id); // Set sideId to the first side's id
    }
  }, [sides]);

  useEffect(() => {
    if (sideId) {
      dispatch(GetSubjects(sideId, 1, 1000));
      dispatch(dataActions.resetQuestion());
    }
  }, [dispatch, sideId]);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  useEffect(() => {
    dispatch(GetQuestions(page, PER_PAGE)).then((re) => {
      setCount(Math.ceil(re.documentCount / PER_PAGE));
    });
  }, [dispatch, page]);
  useEffect(() => {
    if (value) {
      dispatch(GetQuestions(page, PER_PAGE, value)).then((re) => {
        setCount(Math.ceil(re.documentCount / PER_PAGE));
      });
    }
  }, [dispatch, page, value]);
  const ClickDeleteQuestion = async (ID) => {
    dispatch(DeleteQuestions(setOpen, ID)).then(() => {
      if (value) {
        dispatch(GetQuestions(page, PER_PAGE, value)).then((re) => {
          if (re.questions.length < 1) {
            dispatch(GetQuestions(page - 1, PER_PAGE, value));
          }
          setCount(Math.ceil(re.documentCount / PER_PAGE));
        });
      } else {
        dispatch(GetQuestions(page, PER_PAGE)).then((re) => {
          if (re.questions.length < 1) {
            dispatch(GetQuestions(page - 1, PER_PAGE));
          }
          setCount(Math.ceil(re.documentCount / PER_PAGE));
        });
      }
    });
  };

  return (
    <Fragment>
      <div className="subjects bg-bg w-[100%] h-full">
        <div className="flex gap-[21px]">
          {sides.map((e) => (
            <button
              key={e.id}
              onClick={() => setSideId(e._id)}
              className={`group cursor-pointer font-medium transition duration-200 py-[11px] px-[28px]  relative rounded-[10px] ${
                sideId === e.id
                  ? "bg-main text-[white]"
                  : "bg-[white] text-[#8f8f8f]"
              }`}
            >
              {e.name}
            </button>
          ))}
        </div>
        <div className="flex justify-between my-[20px] items-center">
          <div className="flex gap-[21px]">
            <DrowbSubjects
              label="المادة"
              text={[...subjects, { name: "كل المواد", _id: "كل المواد" }]}
              value={value}
              updateValue={updateValue}
              sideId={sideId} // Pass sideId as a prop to DrowbSubjects
            />
          </div>
          <NavLink to="/addQuestion">
            <span className="text-main text-[18px] underline cursor-pointer">
              إضافة سؤال جديدة
            </span>
          </NavLink>
        </div>
        {loading ? (
          <div className="h-full w-full flex items-center justify-center">
            <Loading color="#004556" size="24" />
          </div>
        ) : questions.length === 0 ? (
          <p className="flex justify-center font-[600] text-[24px]">
            لايوجد أسئلة اختر المادة . . .
          </p>
        ) : (
          <div className="flex flex-col">
            <div className="cardsSubject grid grid-cols-3 gap-[40px_60px]">
              {questions?.map((ques, index) => {
                return (
                  <QuestionCard
                    ID={ques?._id}
                    number={`السؤال ${index + 1}`}
                    explain={`شرح السؤال  ${index + 1}`}
                    explanation={
                      ques?.explanation ? ques.explanation : "لايوجد شرح"
                    }
                    ques={ques?.question}
                    open={open}
                    setOpen={setOpen}
                    ondelete={() => ClickDeleteQuestion(ques._id)}
                    questionImage={ques?.questionImage?.url}
                  />
                );
              })}
            </div>
            <PaginationCom page={page} setPage={setPage} count={count} />
          </div>
        )}
      </div>
    </Fragment>
  );
}
